/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "RLN",
  title: "Hi I'm Rory McLaughlin",
  subTitle: emoji(
    "I am a passionate Full Stack Software Developer having experience in building web applications and visual dashboards with JavaScript / Reactjs / Nodejs and some other cool libraries and frameworks."
  ),
  resumeLink: "https://1drv.ms/b/s!AoHDXoJsvWFcgpx80cYG2wZXg2q0AA?e=zaEogC", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/rorymclaughlin432",
  linkedin: "https://www.linkedin.com/in/rory-mclaughlin-562171196/",
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "I'm a Full Stack Software Developer with a mix of experience in Front-End and Back-End",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for web applications"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

skillsIcons : [
    {
      name: "JavaScript",
      link: "https://skillicons.dev/icons?i=js"
    },
    {
      name: "TypeScript",
      link: "https://skillicons.dev/icons?i=typescript"
    },
    {
      name: "Python",
      link: "https://skillicons.dev/icons?i=python"
    },
    {
      name: "React",
      link: "https://skillicons.dev/icons?i=react"
    },
    {
      name: "Node.js",
      link: "https://skillicons.dev/icons?i=nodejs"
    },
    {
      name: "npm",
      link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/npm/npm-original-wordmark.svg"
    },
    {
      name: "HTML5",
      link: "https://skillicons.dev/icons?i=html"
    },
    {
      name: "CSS3",
      link: "https://skillicons.dev/icons?i=css"
    },
    {
      name: "Sass",
      link: "https://skillicons.dev/icons?i=sass"
    },
    {
      name: "PostgreSQL",
      link: "https://skillicons.dev/icons?i=postgres"
    },
    {
      name: "Mongo DB",
      link: "https://skillicons.dev/icons?i=mongodb"
    },
    {
      name: "AWS",
      link: "https://skillicons.dev/icons?i=aws"
    },
    {
      name: "Docker",
      link: "https://skillicons.dev/icons?i=docker"
    },
    {
      name: "Java",
      link: "https://skillicons.dev/icons?i=java"
    },
    {
      name: "Next.js",
      link: "https://skillicons.dev/icons?i=nextjs"
    },
    {
      name: ".Net",
      link: "https://skillicons.dev/icons?i=dotnet"
    },
    {
      name: "Jenkins",
      link: "https://skillicons.dev/icons?i=jenkins"
    },
    {
      name: "Linux",
      link: "https://skillicons.dev/icons?i=linux"
    },
    {
      name: "Postman",
      link: "https://skillicons.dev/icons?i=postman"
    },
    {
      name: "Git",
      link: "https://skillicons.dev/icons?i=git"
    },
    {
      name: "GitHub",
      link: "https://skillicons.dev/icons?i=github"
    },
    {
      name: "VS Code",
      link: "https://skillicons.dev/icons?i=vscode"
    },
    {
      name: "Vercel",
      link: "https://skillicons.dev/icons?i=vercel"
    }

  ],

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "postgresql",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "nextjs",
      fontAwesomeClassname: "fas fa-code"
    },
    {
      /* skillName: "dotnet",
      fontAwesomeClassname: "fas fa-code", */
      skillName: "dotnet",
      fontAwesomeClassname: "fab fa-microsoft",
      style: {
        color: "#5C2D91",
        "&:hover": {
          color: "#645beb"
        }
      }
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      company: "Queen's University Belfast",
      companylogo: require("./assets/images/queenslogo.png"),
      role: "Master of Science in Software Development",
      date: "September 2019 - December 2020",
      descBullets: [
        `Participated in learning how to analyse problems and designing robust and elegant software systems to solve problems.`,
        `Gained skills in programming, web development, database design, software testing and software engineering.`,
        `Opportunity to learn how to work as part of a development team using modern development techniques and tools.`
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Developer",
      company: "Reward Insight",
      companylogo: require("./assets/images/reward.png"),
      date: "August 2022 – November 2023",
      descBullets: [
        "Deployed a large bank API project, enabling the wide distribution of customer loyalty based offers to enhance the customer’s experience using JavaScript, NodeJS, Dotnet and AWS",
        "Initiated the seamless migration of the product to AWS which increased efficiency of the product overall, playing a pivotal role in creating lambdas, setting up buckets, and ensuring cloud compatibility for the application.",
        "Collaborated in constructing repositories to house essential APIs for key components of our GMM product, including refund capabilities and transaction matching functionalities",
        "Managed the LGBTQIA+ club and coordinated events and discussions, such as, Belfast Pride Parade for the company."
      ]
    },
    {
      role: "Consultant",
      company: "KPMG",
      companylogo: require("./assets/images/kpmg.png"),
      date: "February 2021 – August 2022",
      descBullets: [
        "Spearheaded the design and development of charts for a highly refined retail analytics Dashboard, leveraging JavaScript, React, Highcharts, Node.js, npm, cube.js, and Git.",
        "Streamlined a multi-layered dashboard using R and RShiny for a government agency to output staff prison data accumulating a variety of diverse, complex data e.g., Full Time/Part Time, Gender, BAME, vaccination data, etc.",
        "Took over managerial role while project lead was on leave. This involved handling the visualization workstream, covering stand ups and weekly update meetings, presenting Viz team progress to product sponsors, and making significant design decisions with the design team.",
        "Devised the monthly newsletter and the coffee roulette for the team to help team building and build a social hub."
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "Some products that I have worked on",
  projects: [
    {
      image: require("./assets/images/visa3.png"),
      imageSecondary: require("./assets/images/monzo2.jpg"),
      projectName: "Global Merchant Marketplace",
      projectDesc:
        "With Global Merchant Marketplace Visa & Monzo issuers, their cardholders and merchants, will benefit from personalized card-linked offers that drive value to all stakeholders.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.albawaba.net/business/pr/visa-and-reward-launch-global-merchant-marketplace-banks-personalized-card-linked"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/dash2.jpeg"),
      imageSecondary: require("./assets/images/dash3.jpg"),
      projectName: "DASH",
      projectDesc:
        "Dash helps you envisage the future performance of your business. We accent your data with the latest market intelligence and our machine learning algorithms provide rich forecasts allowing you to buy better and seize opportunities.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://kpmgdash.co.uk/"
        }
        //  you can add extra buttons here.
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Certifications 🏆 "),

  achievementsCards: [
    {
      title: "AWS Solutions Architect Associate",
      subtitle:
        "Certification of result for AWS Solutions Architect - July 2023",
      image: require("./assets/images/Open_University_logo.png"),
      imageAlt: "Open_University Logo",
      footerLink: [
        {
          name: "Certificate",
          url: "https://1drv.ms/i/s!AoHDXoJsvWFcgphVjBSTaWt3WUG4-A?e=zITHOX"
        }
      ]
    },
    {
      title: "Professional Diploma in Analytics & AI",
      subtitle:
        "Professional Diploma in Analytics & AI from the Analytics Institute 2022",
      image: require("./assets/images/ainstitute.jpg"),
      imageAlt: "Analytics Institute Logo",
      footerLink: [
        {
          name: "Certificate",
          url: "https://1drv.ms/i/s!AoHDXoJsvWFcgphXIbXy9iZbk4DqvA?e=scPf8f"
        }
      ]
    },

    {
      title: "Krisolis Training",
      subtitle:
        "Krisolis Designing and Building Analytics Solutions with Python - May 2021",
      image: require("./assets/images/krisolis-featured.webp"),
      imageAlt: "Krisolis Logo",
      footerLink: [
        {
          name: "Certificate",
          url: "https://1drv.ms/i/s!AoHDXoJsvWFcgphbHwkQxreRcDPrnw?e=u0i8i6"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact")
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  contactInfo,
  isHireable
};
