import React, { useState } from "react";
import "./SoftwareSkill.scss";
import {skillsSection} from "../../portfolio";

export default function SoftwareSkill() {
  const [hoveredSkill, setHoveredSkill] = useState(null);

  const handleHover = (name) => {
    setHoveredSkill(name);
    
  };

  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.skillsIcons.map((skills, i) => {
            return (
              <li
                key={i}
                className={`software-skill-inline ${
                  hoveredSkill === skills.name ? "software-skill-hover" : null
                }`}
                name={skills.name}
                style={{ color: "black" }}
                onMouseEnter={() => handleHover(skills.name)}
                onMouseLeave={() => handleHover(null)}
              >
                <img src={skills.link} alt={skills.name}></img>
                <p style={{ 
                  color: hoveredSkill === skills.name ? "#645beb": null, 
                  fontWeight: hoveredSkill === skills.name ? "bold": null
                  }}>{skills.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
