import React, {useContext} from "react";
import "./Education.scss";
//import EducationCard from "../../components/educationCard/EducationCard";
import {educationInfo} from "../../portfolio";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";


export default function Education() {
  const {isDark} = useContext(StyleContext);
  if (educationInfo.display) {
    return (
  /*     <div className="education-section" id="education">
        <h1 className="education-heading">Education</h1>
        <div className="education-card-container">
          {educationInfo.schools.map((school, index) => (
            <EducationCard key={index} school={school} />
          ))}
        </div>
      </div>
 */
<div id="experience">
<Fade bottom duration={1000} distance="20px">
  <div className="experience-container" id="workExperience">
    <div>
      <h1 className="experience-heading">Education</h1>
      <div className="experience-cards-div">
        {educationInfo.schools.map((card, i) => {
          return (
            <ExperienceCard
              key={i}
              isDark={isDark}
              cardInfo={{
                company: card.company,
                desc: card.desc,
                date: card.date,
                companylogo: card.companylogo,
                role: card.role,
                descBullets: card.descBullets
              }}
            />
          );
        })}
      </div>
    </div>
  </div>
</Fade>
</div>
    );
  }
  return null;
}
